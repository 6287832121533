import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FileSaverService } from 'ngx-filesaver';

@Injectable({
  providedIn: 'root',
})
export class SharedAppService {
  private chooseApp: number | null = null;
  cloudServerBillingStatus = new BehaviorSubject<boolean | null>(null);
  cloudCurrentOrganization = new BehaviorSubject<string | null>(null);
  currentOrgAdministrator = new BehaviorSubject<boolean | null>(null);
  enterpriseAdmin = new BehaviorSubject<boolean | null>(null);
  constructor(private FileSaverService: FileSaverService) { }
  /**
   * @param detect: numbers ,1 for server, 2 for  cloud, 3 for enterprise, 4 for business, 5 for Sites
   * @returns void
   */

  selectApp(detect: number): void {
    // const serverOrgId = '85d599a7-f05b-404e-aff3-fdcaac5e98d3';
    // const serverOrgName = 'OpenBots';
    // if (organizationsArr && organizationsArr.length) {
    //   if (
    //     organizationsArr[0].id == serverOrgId &&
    //     organizationsArr[0].name == serverOrgName
    //   ) {
    //     this.chooseApp = 0; // 0 means server
    //   } else this.chooseApp = 1; // 1 means cloud
    // }
    // 1 means server
    // 2 means cloud
    // 3 means enterprise
    // 4 means business
    // 5 means sites
    // 6 means comply
    if (detect)
      this.chooseApp = detect;

  }

  /**
   * @author which app you are curerntly using
   * @returns 1 means server , 2 means cloud , 3 means enterprise
   */
  currentApp(): number {
    if (this.chooseApp) {
      return this.chooseApp;

    }

  }

  // cloud organization billing status
  cloudOrgBillingStatus(param: boolean): void {
    this.cloudServerBillingStatus.next(param);
  }

  // for shared-app internal modules where we need to check billing status
  cloudBillingStatus(): Observable<boolean> {
    return this.cloudServerBillingStatus.asObservable();
  }

  // below function take id of current organization from cloud
  cloudCurrentOrg(id: string): void {
    this.cloudCurrentOrganization.next(id);
  }

  // this function we will call to get current organization that user selected
  curentCloudOrgId(): Observable<string> {
    return this.cloudCurrentOrganization.asObservable();
  }

  currentCloudOrgAdministrator(param: boolean) {
    this.currentOrgAdministrator.next(param)
  }

  getCurrentOrgAdministrator(): Observable<boolean> {
    return this.currentOrgAdministrator.asObservable();
  }

  // for enterprise only
  setEnterpriseAdmin(param: boolean) {
    this.enterpriseAdmin.next(param);
  }

  // for enterprise only
  getEnterpriseAdmin(): Observable<boolean> {
    return this.enterpriseAdmin.asObservable();
  }
}
