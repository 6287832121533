import { Inject, Injectable } from '@angular/core';
import { BusinessHttpService } from './http.service';
import { HttpHeaders } from '@angular/common/http';
// import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { GetUserInfo } from '../../interfaces/getUserInfo';

import { BillingStatusAPiUrl } from '../../webApiUrls/billingStatus';
import { BillingStatus } from '../../interfaces/billingSatus';
import { AppDefinitionApiUrl } from '../../webApiUrls/WorkFlowApiUrls';
import { SharedAppService } from 'projects/sharedapp/src/app/sharedServices/sharedServiceUrl';
import { BusinessHelperService } from './helper.service';
import { FeatureFlagService } from '../../../../../sharedapp/src/app/sharedServices/feature-flag.service';
import { businessEnv } from 'projects/business-centre/src/environments/environment';

import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};



@Injectable({
  providedIn: 'root',
})
export class BusinessAuthService {
  headerData = new HttpHeaders({ 'Content-Type': 'application/json' });
  suscription: Subscription[];
  private loginSubject = new Subject<boolean>();
  loginChanged = this.loginSubject.asObservable();

  userSubjectBehaviour = new BehaviorSubject<any>(null);
  appDefinitionSubjectBehaviour = new BehaviorSubject<any>(null);
  nextBillDateSubjectBehaviour = new BehaviorSubject<any>(null);
  gerUserInfoResponse: GetUserInfo;
  isBlocked: boolean;
  userAvailable: boolean = false;
  isBlockedSubscription: boolean;
  billingStatusSubject = new BehaviorSubject<any>(null);
  billingSubscriptionStatusSubject = new BehaviorSubject<any>(null);
  constructor(
    private httpService: BusinessHttpService,
    private helperService: BusinessHelperService,
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private sharedAppService: SharedAppService,
    private featureFlagService: FeatureFlagService
  ) {
    this.loginSubject.next(false);
  }

  refreshToken() {
  }

  storeToken(data) {
    localStorage.setItem('access_token', data.jwt);
    localStorage.setItem('refresh_token', data.refreshToken);
  }

  // }

  // getAppdefinition() {
  //   this.httpService
  //     .getByCompleteUrl(
  //       `${AppDefinitionApiUrl.AppDefinitionList}?$orderby=name`
  //     )
  //     .subscribe((response) => {
  //       if (response) {
  //         this.appDefinitionSubjectBehaviour.next(response.items);
  //       }
  //     });
  // }

  getUserInfo(): void {
    this.httpService
      .getUserInfoAndFeatures(
        `Auth/GetUserInfo`,
        `FeatureFlags/openbots.cloudserver`
      )
      .subscribe((responseList: any) => {
        if (responseList) {
          // get user info
          let response = responseList[0];
          this.featureFlagService.config = responseList[1];

          console.log('responseList', responseList);

          this.sharedAppService.selectApp(4);
          // get feature flags

          if (response.myOrganizations && response.myOrganizations.length)
            this.helperService.organizationChanged.next(
              response.myOrganizations[0].id
            );

          this.gerUserInfoResponse = { ...response };

          localStorage.setItem(
            'forcedPasswordChange',
            response.forcedPasswordChange
          );
          localStorage.setItem(
            'isJoinOrgRequestPending',
            response.isJoinOrgRequestPending
          );
          localStorage.setItem(
            'isUserConsentRequired',
            response.isUserConsentRequired
          );
          localStorage.setItem('personId', response.personId);
          localStorage.setItem('userName', response.userName);
          localStorage.setItem('UserEmail', response.email);
          sessionStorage.setItem('loggedInUser', JSON.stringify(response));
          this.userSubjectBehaviour.next(response);
          if (response.myOrganizations && response.myOrganizations.length) {
            const url = window.location.href;
            let splitUrl = '';
            let secondUrl = '';
            let thridUrl = '';
            if (url.includes('saveOrgId')) {
              splitUrl = url.split('?')[1];
              secondUrl = splitUrl.split('&')[0];
              thridUrl = secondUrl.split('saveOrgId=')[1];
              console.log(url);
              console.log(splitUrl.split('&'));
              console.log(secondUrl.split('saveOrgId='));
              console.log(thridUrl);
            }

            const shareTentKey = thridUrl;
            // const shareTentKey = window.location.search.replace('?', '');
            if (shareTentKey != '') {
              for (let dataMyOrganizations of response.myOrganizations) {
                if (shareTentKey == dataMyOrganizations.tenantKey) {
                  this.helperService.organizationChanged.next(
                    dataMyOrganizations.id
                  );
                  localStorage.setItem('saveOrgId', dataMyOrganizations.id);
                  localStorage.setItem(
                    'organizationId',
                    dataMyOrganizations.id
                  );
                  localStorage.setItem(
                    'tenantKey',
                    dataMyOrganizations.tenantKey
                  );
                  localStorage.setItem('orgname', response.myOrganizations[0].name);
                  this.getbillingStatus(dataMyOrganizations.id);
                  this.sharedAppService.cloudCurrentOrg(dataMyOrganizations.id);
                  this.helperService.organizationChanged.next(
                    dataMyOrganizations.id
                  );
                }
              }
            } else if (shareTentKey == '') {
              if (localStorage.getItem('saveOrgId') != null) {
                for (let mainData of response.myOrganizations) {
                  if (mainData.id == localStorage.getItem('saveOrgId')) {
                    this.helperService.organizationChanged.next(mainData.id);
                    localStorage.setItem('saveOrgId', mainData.id);
                    localStorage.setItem('organizationId', mainData.id);
                    localStorage.setItem('tenantKey', mainData.tenantKey);
                    this.getbillingStatus(mainData.id);
                    this.sharedAppService.cloudCurrentOrg(mainData.id);
                  }
                }
              } else if (localStorage.getItem('saveOrgId') == null) {
                this.helperService.organizationChanged.next(
                  response.myOrganizations[0].id
                );
                localStorage.setItem(
                  'organizationId',
                  response.myOrganizations[0].id
                );
                localStorage.setItem(
                  'tenantKey',
                  response.myOrganizations[0].tenantKey
                );
                this.getbillingStatus(response.myOrganizations[0].id);
                this.sharedAppService.cloudCurrentOrg(
                  response.myOrganizations[0].id
                );
              }
            }

            // if (response.myOrganizations && response.myOrganizations.length)
            //   this.helperService.organizationChanged.next(
            //     response.myOrganizations[0].id
            //   );
          }

          this.userAvailable = true;
        }
      });
  }


  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        if (this.msalGuardConfig.authRequest) {
            this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
                .subscribe((response: AuthenticationResult) => {
                    this.msalService.instance.setActiveAccount(response.account);
                });
        } else {
            this.msalService.loginPopup(userFlowRequest)
                .subscribe((response: AuthenticationResult) => {
                    this.msalService.instance.setActiveAccount(response.account);
                });
        }
    } else {
        if (this.msalGuardConfig.authRequest) {
            this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
        } else {
            this.msalService.loginRedirect(userFlowRequest);
        }
    }
}


  getUserInfoBySubject(): Observable<GetUserInfo> {
    return this.userSubjectBehaviour.asObservable();
  }
  getAppDefinitionBySubject(): Observable<any> {
    return this.appDefinitionSubjectBehaviour.asObservable();
  }

  getNextBillDate(): Observable<any> {
    return this.nextBillDateSubjectBehaviour.asObservable();
  }
  getbillingStatus(id?: string): void {
    this.httpService.startLoader();
    // this.getAppdefinition();

    if (id) {
      this.httpService
        .get(
          `${BillingStatusAPiUrl.billing}/${BillingStatusAPiUrl.OrganizationBillingStatus}`
        )
        .subscribe(
          (response: BillingStatus) => {
            if (response) {
              this.nextBillDateSubjectBehaviour.next(response);
              this.isBlocked =
                response.isStorageUploadBlocked ||
                response.isStorageDownloadBlocked ||
                response.isStorageDeletionBlocked ||
                response.areAgentsBlocked ||
                response.isExecutionBlocked ||
                response.isLoggingBlocked;
              this.isBlockedSubscription =
                response.isExecutionBlocked == true ||
                response.subscriptionStatus == 'canceled' ||
                response.subscriptionStatus == 'unpaid';
              localStorage.setItem(
                'subscriptionStatus',
                response.subscriptionStatus
              );
              localStorage.setItem('nextBillDate', response.nextBillDate);
              this.httpService.stopLoader();
              this.billingStatusSubject.next(this.isBlocked);
              this.billingSubscriptionStatusSubject.next(
                this.isBlockedSubscription
              );
              this.organizationBillingStatus(this.isBlocked);
            }
          },
          () => this.httpService.stopLoader()
        );
    } else {
      this.checkBiilingStatus();
    }
  }

  checkBiilingStatus() {
    this.httpService
      .get(
        `${BillingStatusAPiUrl.billing}/${BillingStatusAPiUrl.OrganizationBillingStatus}`
      )
      .subscribe(
        (response: BillingStatus) => {
          if (response) {
            this.nextBillDateSubjectBehaviour.next(response);
            this.isBlocked =
              response.isStorageUploadBlocked ||
              response.isStorageDownloadBlocked ||
              response.isStorageDeletionBlocked ||
              response.areAgentsBlocked ||
              response.isExecutionBlocked ||
              response.isLoggingBlocked;
            this.isBlockedSubscription =
              response.isExecutionBlocked == true ||
              response.subscriptionStatus == 'canceled' ||
              response.subscriptionStatus == 'unpaid';
            this.httpService.stopLoader();
            this.billingSubscriptionStatusSubject.next(
              this.isBlockedSubscription
            );
            this.billingStatusSubject.next(this.isBlocked);
            this.organizationBillingStatus(this.isBlocked);
          }
        },
        () => this.httpService.stopLoader()
      );
  }

  featureFlag() {
    this.httpService.get(`FeatureFlags/openbots.cloudserver`).subscribe(
      (response) => {
        if (response) {
          this.featureFlagService.config = response;
        }
      },
      () => this.httpService.stopLoader()
    );
  }
  // billing subject
  currentOrgBillingStatus() {
    return this.billingStatusSubject.asObservable();
  }

  // Subscription subject
  currentOrgBillingFlagSubscription() {
    return this.billingSubscriptionStatusSubject.asObservable();
  }
  organizationBillingStatus(param: boolean) {
    return false; // temporary added
    // this.sharedAppService.cloudOrgBillingStatus(param);
  }
}
