<ngx-pwa *ngIf="isBlockedFlag"></ngx-pwa>


<div *ngIf="!isBlockedFlag">
  <router-outlet *ngIf="!isIframe"></router-outlet>
  <ng-template #continueWorkSession let-ref="dialogRef">
    <nb-card>
      <nb-card-header>
        <h6>Idle Time</h6>
      </nb-card-header>
      <nb-card-body>
        <div aria-labelledby="title">
          <p><strong>Note:</strong> You have been idle for <strong>20 minutes</strong> and will be logged out in
            <strong>{{countdown}} seconds</strong>
          </p>
          <p>Press ok to continue your session.</p>
        </div>

      </nb-card-body>
      <nb-card-footer>
        <button class="btn btn-light" style="float: right;" (click)="onContinueWork(ref)">
          Ok
        </button>
      </nb-card-footer>
    </nb-card>
  </ng-template>
</div>
