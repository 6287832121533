import { Injectable } from "@angular/core";
import { SharedHttpService } from "./shared-http.service";

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  config: any = null;
  configUrl = `FeatureFlags/`;
  getTenantkeyValue: any = null
  constructor(private httpService: SharedHttpService) { }

  loadConfig(serviceTag) {
    this.httpService.get(`${this.configUrl}${serviceTag}`).subscribe(data => {
      this.config = data;
    })
  }

  isFeatureEnabled(key: string): boolean {
    if (this.config) {
      const feature = this.config.find(elem => elem.name == key);
      return feature ? feature.flag : false;
    }
  }


  getTenantKey(key?) {
    this.getTenantkeyValue = key
    return this.getTenantkeyValue

  }
}
