import { Location } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { BehaviorSubject, Observable } from 'rxjs';
import { AllFileSizePipe } from '../sharedCoreModule/pipe/all-file-size.pipe';
import { FileSizePipe } from '../sharedCoreModule/pipe/filesize.pipe';
import { TimeDatePipe } from '../sharedCoreModule/pipe/time-date.pipe';
import { TimeZonePipe } from '../sharedCoreModule/pipe/time-zone.pipe';
import { UTCtoLocalTimeZonePipe } from '../sharedCoreModule/pipe/utcto-local-time-zone.pipe';
import { Rule, Usage } from '../sharedInterfaces/ipFencing';
import { ItemsPerPage } from '../sharedInterfaces/itemsPerPage';
import { SharedHttpService } from '../sharedServices/shared-http.service';
import { HttpResponse } from '@angular/common/http';
import { FileSaverService } from 'ngx-filesaver';


@Injectable({
  providedIn: 'root',
})
export class SharedHelperService {
  organizationChanged = new BehaviorSubject<any>(
    localStorage.getItem('organizationId')
  );
  AppDefinitionChanged = new BehaviorSubject<any>(
    localStorage.getItem('AppID')
  );

  AppDefinitionModified = new BehaviorSubject<any>(null);

  itemPerPage: ItemsPerPage[];
  constructor(
    private FileSaverService: FileSaverService,
    private httpService: SharedHttpService,
    private toastrService: NbToastrService,
    private location: Location
  ) { }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  getItemsPerPage(): ItemsPerPage[] {
    return (this.itemPerPage = [
      // { id: 5, name: '5 per page' },
      { id: 10, name: '10 per page' },
      { id: 20, name: '20 per page' },
      { id: 30, name: '30 per page' },
      { id: 50, name: '50 per page' },
      { id: 100, name: '100 per page' },
    ]);
  }

  transformDate(value, format: string) {
    const pipe = new TimeDatePipe();
    return pipe.transform(value, format);
  }

  changeBoolean(value: boolean | string): string {
    if (value) return 'Yes';
    else return 'No';
  }

  getETagHeaders(etag: string) {
    const headers = new HttpHeaders({ 'If-Match': etag });
    return headers;
  }

  getUsage(): Usage[] {
    return [
      { name: 'Allow', value: 1 },
      { name: 'Deny', value: -1 },
    ];
  }

  getRules(): Rule[] {
    return [
      { name: 'IPv4', value: 1 },
      { name: 'IPv4Range', value: 2 },
      { name: 'IPv6', value: 3 },
      { name: 'IPv6Range', value: 4 },
      { name: 'HTTP Header', value: 5 },
    ];
  }

  getFileSize(param: number): string | string[] {
    const fileSize = new FileSizePipe();
    return fileSize.transform(param);
  }

  getChangedOrgId(): Observable<any> {
    return this.organizationChanged.asObservable();
  }
  getChangedAppId(): Observable<any> {
    return this.AppDefinitionChanged.asObservable();
  }

  UTCTimeToLocal(param: string) {
    const utcTIme = new TimeZonePipe();
    return utcTIme.transform(param);
  }

  localToUTCTime(param: string) {
    return new Date(param);
  }
  bytesIntoMB(bytes: number): number {
    return bytes / (1024 * 1024);
  }

  bytesIntoGB(bytes: number): number {
    return bytes / (1024 * 1024 * 1024);
  }

  megaBytesIntiBytes(bytes: number): number {
    return bytes * (1024 * 1024);
  }

  gegaBytesIntiBytes(bytes: number): number {
    return bytes * (1024 * 1024 * 1024);
  }

  bytesIntoMBorGB(storageSize: number): string[] {
    const data = new AllFileSizePipe();
    return data.transform(storageSize).split(' ');
  }

  UTCtoLocalTimeZone(value, format = 'lll') {
    const convertedTime = new UTCtoLocalTimeZonePipe();
    return convertedTime.transform(value, format);
  }

  UTCtoLocalTimeZoneWorkTask(value, format = 'll') {
    const convertedTime = new UTCtoLocalTimeZonePipe();
    return convertedTime.transform(value, format);
  }

  success(param): void {
    this.toastrService.success(`${param}`, 'Success');
  }

  error(param): void {
    this.toastrService.danger(`${param}`, 'Error');
  }

  warning(param): void {
    this.toastrService.warning(`${param}`, 'Warning');
  }
  primary(param): void {
    this.toastrService.primary(`${param}`, 'Info');
  }

  info(param): void {
    this.toastrService.info(`${param}`, 'Info');
  }

  changedData(dataArray) {
    return dataArray.map((select) => {
      return Object.assign({}, select, {
        isSelected: false,
      });
    });
  }
  selectAll(dataArray, change) {
    return dataArray.map((select) => {
      return Object.assign({}, select, {
        isSelected: change,
      });
    });
  }

  backScreen(): void {
    this.location.back();
  }

  exportFile(baseUrl: string, pageSize: number, pageNumber: number, filter: string = '', orderBy: string = 'createdOn+desc', continuationToken: string = '', fileType: string = 'zip') {
    let exportUrl = this.buildExportUrl(baseUrl, pageSize, pageNumber, filter, orderBy);

    const options = {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body',
      headers: {
        'continuationToken': continuationToken,
        'Accept': this.getContentType(fileType)
      }
    };

    this.httpService.get(exportUrl, options).subscribe({
      next: (data: HttpResponse<Blob>) => {
        if (data?.headers) {
          const fileName = this.getFileName(data.headers, fileType);
          this.FileSaverService.save(data.body, fileName);
        }
      },
      error: (error) => {
        console.error('Export failed:', error);
      }
    });
  }

  exportAllAutomationLogs(url: string, fileType: string = 'zip'): Observable<any> {
    const options = {
      responseType: 'blob' as 'json',
      observe: 'response' as 'body',
      headers: {
        'Accept': this.getContentType(fileType)
      }
    };

    return this.httpService.get(url, options);
  }
  private buildExportUrl(baseUrl: string, pageSize: number, pageNumber: number, filter: string, orderBy: string): string {
    const separator = baseUrl.includes('?') ? '&' : '?';
    let exportUrl = `${baseUrl}${separator}$orderby=${orderBy}`;

    if (filter) {
      exportUrl += `&$filter=${filter}`;
    }

    exportUrl += `&$top=${pageSize}&$skip=${(pageNumber - 1) * pageSize}`;
    return exportUrl;
  }

  private getContentType(fileType: string): string {
    const contentTypes = {
      'csv': 'text/csv',
      'zip': 'application/zip',
      'json': 'application/json'
    };
    return contentTypes[fileType.toLowerCase()] || 'text/csv';
  }

  private getFileName(headers: HttpHeaders, fileType: string): string {
    const contentDisposition = headers.get('content-disposition');
    const fileNameMatch = contentDisposition?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
    const defaultFileName = `export.${fileType}`;
    return fileNameMatch && fileNameMatch.length > 1
      ? fileNameMatch[1].replace(/\"/g, '')
      : defaultFileName;
  }
}
