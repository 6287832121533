import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NbToastrService } from '@nebular/theme';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { environment } from '../../../../cloudserver/src/environments/environment';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { SharedAppService } from './sharedServiceUrl';
import { ServerEnvironment } from '../../../../server/src/environments/serverEnv';
import { Apps } from '../pages/current-app/apps';
import { EnterpriseEnvironment } from '../../../../enterprise/src/environments/environment';
import { businessEnv } from '../../../../business-centre/src/environments/environment';
import { catchError, retry, timeout } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class SharedHttpService {
  galaxyapiUrl = environment.Galaxy_API_URL;
  baseUrl: string;
  @BlockUI() blockUI: NgBlockUI;
  countapi = 0;
  chooseCurrentRolesPermission: any;
  rolesPermissionList: any = [];
  constructor(
    private http: HttpClient,
    private toastrService: NbToastrService,
    private sharedAppService: SharedAppService
  ) { }

  get apiUrl(): string {
    if (this.sharedAppService.currentApp() == Apps.enterprise) {
      return (this.baseUrl = EnterpriseEnvironment.apiUrl + '/');
    } else if (this.sharedAppService.currentApp() == Apps.cloud)
      return (this.baseUrl = `${environment.API_URL}/${environment.VERSION_URL}`);
    else if (this.sharedAppService.currentApp() == Apps.server)
      return (this.baseUrl = ServerEnvironment.apiUrl + '/');
    else if (this.sharedAppService.currentApp() == Apps.business) {
      return (this.baseUrl = `${businessEnv.API_URL}/${businessEnv.VERSION_URL}`);
    }
    // let orgId: string;
    // this.helperService.getChangedOrgId().subscribe((response) => {
    //   orgId = response;
    // });
    // return `${environment.API_URL}/${environment.VERSION_URL}Organizations/${orgId}/`;
    // return `${environment.API_URL}/${environment.VERSION_URL}`;
  }

  get apiStartPoints() {
    return `${environment.API_URL}/${environment.VERSION_URL}`;
  }

  get apiStartPointsVersion1() {
    return `${environment.API_URL}/api/v1/`;
  }

  watchtotalSubject = new BehaviorSubject<any>('');
  currentMessagetotal = this.watchtotalSubject.asObservable();

  getHealthStatus(endpoint: string): Observable<any> {
    return this.http.get(`${environment.healthUrl}/${endpoint}`);
  }

  // Utility method to merge default options
  private mergeDefaultOptions(options?): object {
    const defaultOptions = {
      withCredentials: true
    };
    return {...defaultOptions, ...options};
  }

  // below 5 methods are used with prefix organization keyword and organizationId
  get(endpoint: string, options?): Observable<any> {
    const mergedOptions = this.mergeDefaultOptions(options);
    return this.http.get(`${this.apiUrl}${endpoint}`, mergedOptions);
  }
  getVersion1Galaxy(endpoint: string, options?): Observable<any> {
    return this.http.get(`${this.galaxyapiUrl}${endpoint}`, options);
  }
  getVersion1(endpoint: string, options?): Observable<any> {
    const mergedOptions = this.mergeDefaultOptions(options);
    return this.http.get(`${this.apiUrl}${endpoint}`, mergedOptions);
  }

  post(endpoint: string, data?, options?): Observable<any> {
    const mergedOptions = this.mergeDefaultOptions(options);
    return this.http.post(`${this.apiUrl}${endpoint}`, data, mergedOptions);
  }

  postbyUrl(endpoint: string, body): Observable<any> {
    // 120000  2min
    // 180000 3min
    // 90000 1.5 seconds
    // 60000 1 seconds
    return this.http
      .post(`${endpoint}`, body, {
        // reportProgress: true,
        // observe: 'events',
      })
      .pipe(timeout(90000), retry(2), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }

  postVariable(endpoint: string, data?) {
    return this.http.post(`${this.apiUrl}${endpoint}`, data);
  }

  uploadFile(endpoint: string, files): Observable<any> {
    const formData = new FormData();
    // formData.append('file', fileToUpload, fileToUpload.name);
    files.forEach((file) => {
      formData.append('file', file, file.name);
    });
    return this.http.post(`${this.apiUrl}${endpoint}`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  put(endpoint: string, data, options?): Observable<any> {
    const mergedOptions = this.mergeDefaultOptions(options);
    return this.http.put(`${this.apiUrl}${endpoint}`, data, mergedOptions);
  }

  patch(endpoint: string, data, options?): Observable<any> {
    const mergedOptions = this.mergeDefaultOptions(options);
    return this.http.patch(`${this.apiUrl}${endpoint}`, data, mergedOptions);
  }

  delete(endpoint: string, options?): Observable<any> {
    const mergedOptions = this.mergeDefaultOptions(options);
    return this.http.delete(`${this.apiUrl}${endpoint}`, mergedOptions);
  }
  // ends here

  // below 5 methods are used without organization keyword and organizationId
  getByCompleteUrlVersion1(endpoint: string, options?): Observable<any> {
    return this.http.get(`${this.apiStartPointsVersion1}${endpoint}`, options);
  }
  postByCompleteUrlVersion1(
    endpoint: string,
    data?,
    options?
  ): Observable<any> {
    return this.http.post(
      `${this.apiStartPointsVersion1}${endpoint}`,
      data,
      options
    );
  }

  getByCompleteUrl(endpoint: string, options?): Observable<any> {
    return this.http.get(`${this.apiStartPoints}${endpoint}`, options);
  }

  postByCompleteUrl(endpoint: string, data?, options?): Observable<any> {
    return this.http.post(`${this.apiStartPoints}${endpoint}`, data, options);
  }

  putByCompleteUrl(endpoint: string, data, options?): Observable<any> {
    return this.http.put(`${this.apiStartPoints}${endpoint}`, data, options);
  }

  patchByCompleteUrl(endpoint: string, data, options?): Observable<any> {
    return this.http.patch(`${this.apiStartPoints}${endpoint}`, data, options);
  }

  deleteByCompleteUrl(endpoint: string, options?): Observable<any> {
    return this.http.delete(`${this.apiStartPoints}${endpoint}`, options);
  }
  // ends here

  success(param): void {
    this.toastrService.success(`${param}`, 'Success');
  }

  error(param): void {
    this.toastrService.danger(`${param}`, 'Error');
  }

  warning(param): void {
    this.toastrService.warning(`${param}`, 'Warning');
  }
  primary(param): void {
    this.toastrService.primary(`${param}`, 'Info');
  }

  info(param): void {
    this.toastrService.info(`${param}`, 'Info');
  }

  /// for 429 error with data sharing flag .
  watchtotal(error, time) {
    const errorTime = {
      error,
      time,
    };
    this.countapi = 1;
    this.watchtotalSubject.next(errorTime);
  }

  getOrganizationId(): string {
    return localStorage.getItem('organizationId');
  }
  ///////Loader
  startLoader(message?: string) {
    if (message) this.blockUI.start(message);
    else this.blockUI.start();
  }

  stopLoader() {
    this.blockUI.stop();
  }

  //Security/OrganizationUnit/f37042db-b799-4133-94bd-5f7a0f491c11/rolepermissions
  checkRolesStatus(id) {
    if (id) {
      this
        .get(
        `AppDefinitions/${id}/RolePermissions`
      ).subscribe((response: []) => {
        if (response) {
          this.rolesPermissionList = response;
        }
      });
    } else {
      this.rolesPermissionList = '';
    }
  }

  rolesFilterByModules(moduleName, action) {
    if (this.rolesPermissionList.permissions != undefined) {
      // let mainData;
      // mainData = this.rolesPermissionList.permissions.filter(
      //   (data) => data.entity == moduleName && data.action == action
      // );
      // console.log(mainData, 'p1');

      // if (mainData[0].scope == 'Allow') {
      //   return (this.chooseCurrentRolesPermission = false);
      // }
      // if (mainData[0].scope == 'Deny') {
      //   return (this.chooseCurrentRolesPermission = true);
      // }

      for (let data of this.rolesPermissionList.permissions) {
        // Convert both sides of the comparison to the same case
        if ((data.entity?.toLowerCase() ?? '') === (moduleName?.toLowerCase() ?? '') && (data.action?.toLowerCase() ?? '') === (action?.toLowerCase() ?? '')) {
          if (data.scope.toLowerCase() === "allow") {
                console.log(data.scope, moduleName)
                return this.chooseCurrentRolesPermission = false
            }
            if (data.scope.toLowerCase() === "deny") {
                console.log(data.scope, moduleName)
                return this.chooseCurrentRolesPermission = true
            }
        }
    }
      console.log(this.chooseCurrentRolesPermission)
      return this.chooseCurrentRolesPermission;
    } else {
      return null;
    }
  }
}
