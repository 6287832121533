import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
// import { OAuthService } from 'angular-oauth2-oidc';
import { Organizations } from '../../../interfaces/organizations';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppDefinition } from '../../../interfaces/appDefinition';
import { SharedHelperService } from 'projects/sharedapp/src/app/sharedServices/shared-helper.service';
import { SharedAppService } from 'projects/sharedapp/src/app/sharedServices/sharedServiceUrl';
import { businessEnv } from '../../../../environments/environment';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
// import { businessAuthConfig } from 'projects/business-centre/src/business-oidc-config';
import { BusinessAuthService } from '../../../@core/services/auth.service';
import { BusinessHelperService } from '../../../@core/services/helper.service';
import { SharedHttpService } from 'projects/sharedapp/src/app/sharedServices/shared-http.service';
import { FeatureFlagService } from 'projects/sharedapp/src/app/sharedServices/feature-flag.service';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  selectedItem = '2';
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];
  subscription: Subscription;
  myOrganization: Organizations[] = [];
  myAppDefinition: AppDefinition[] = [];
  userName: string;
  headerForm: FormGroup;
  user_email: string = '';
  subscriptionStatus: string = '';
  SubscriptionLink;
  previewIcon: boolean = false;
  rightMenuOpened: boolean = false;
  nextBillDate;
  dueDate;
  username: '';
  usernamelist = [];
  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    protected router: Router,
    // private oAuthService: OAuthService,
    private authService: BusinessAuthService,
    private helperService: BusinessHelperService,
    private ShelperService: SharedHelperService,
    private fb: FormBuilder,
    private sharedAppService: SharedAppService,
    private cdr: ChangeDetectorRef,
    private SharedhttpService: SharedHttpService,
    private featureFlagService: FeatureFlagService
  ) {
    // this.oAuthService.configure(businessAuthConfig);

    this.headerForm = this.fb.group({
      id: [''],
      appID: [''],
    });

    this.ShelperService.AppDefinitionModified.subscribe((data) => {
      if (data) {
        if (data.isPush) {
          // update apps dropdown with new app
          this.myAppDefinition = [...this.myAppDefinition, data.app];
          this.helperService.AppDefinitionChanged.next(data.app.id);
          localStorage.setItem('AppID', data.app.id);
        } else if (data.isUpdated) {
          // update apps dropdown with updated app
          let UpdatedAppIndex = this.myAppDefinition.findIndex(
            (x) => x.id == data.app.id
          );
          this.myAppDefinition[UpdatedAppIndex] = { ...data.app };
          localStorage.setItem('AppID', data.app.id);
          this.helperService.AppDefinitionChanged.next(data.app.id);
        } else if (data.isDeleted) {
          // remove app from apps dropdown
          let myAppDefinition: AppDefinition[];
          myAppDefinition = this.myAppDefinition;
          let updatedAppDefinition: AppDefinition[] = [];
          if (typeof data.appId == 'string') {
            updatedAppDefinition = myAppDefinition.filter(
              (x) => x.id != data.appId
            );
          } else {
            updatedAppDefinition = _.differenceBy(
              myAppDefinition,
              data.appId,
              'id'
            );
          }
          this.myAppDefinition = [...updatedAppDefinition];
          localStorage.removeItem('AppID');
          this.helperService.AppDefinitionChanged.next(null);
        }
      } else {
        this.helperService.AppDefinitionChanged.next(null);
        localStorage.removeItem('AppID');
      }
    });

    // this.authService.getAppDefinitionBySubject().subscribe((res) => {
    //   this.myAppDefinition = res;
    //   // if (this.myAppDefinition && this.myAppDefinition.length > 0) {
    //   //   this.onAppDefinitionChanged(this.myAppDefinition[0].id);
    //   //  // this.headerForm.patchValue({ appID: this.myAppDefinition[0].id })
    //   // }
    // });
    this.SubscriptionLink = businessEnv.SubscriptionLink;
    this.previewIcon = businessEnv.previewShow;
  }

  getMenuItems() {
    return [{ title: 'Log out', link: '/auth/logout' }];
  }

  ngOnInit() {
    this.authService.getNextBillDate().subscribe((data: any) => {
      if(data) {
      this.user_email = localStorage.getItem('UserEmail');
      this.usernamelist = localStorage.getItem('UserEmail').split('.');
      if(this.usernamelist)
      this.username =
        this.usernamelist[0]?.charAt(0) + this.usernamelist[1]?.charAt(0);
      // this.subscriptionStatus = localStorage.getItem("subscriptionStatus")
      this.subscriptionStatus = data?.subscriptionStatus;
      this.nextBillDate = this.helperService.UTCtoLocalTimeZone(
        data?.nextBillDate
      );
      // this.nextBillDate = this.helperService.UTCtoLocalTimeZone(localStorage.getItem('nextBillDate'));
      var currentDate = moment(new Date());
      var givenDate = moment(data?.nextBillDate);
      // var givenDate = moment(localStorage.getItem('nextBillDate'));
      this.dueDate = givenDate.diff(currentDate, 'days') + 1;
    }
    });

    const shareTentKey = window.location.search.replace('?', '');
    this.authService.getUserInfoBySubject().subscribe((res) => {
      if (res) {
        this.myOrganization = [...res.myOrganizations];
        for (let data of this.myOrganization) {
          if (localStorage.getItem('tenantKey') == data.tenantKey) {
            this.headerForm.patchValue({ id: data.id });
            this.onOrganizationChanged(data);
          }
        }
        let data

        data = this.myOrganization.filter(data => data.name === localStorage.getItem('orgname'))
        if(data && data.length > 0) {
          this.myAppDefinition = data[0].appDefinitions;
        }
      }
    });

    this.cdr.detectChanges()
    this.headerForm.get('appID').reset();
  }
  ngOnChanges() {
    this.cdr.detectChanges();
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.subscription) this.subscription.unsubscribe();
  }

  logout(): void {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = `${businessEnv.AUTH_ISSUER}/Logout`;
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  openRightmenu() {
    this.rightMenuOpened = !this.rightMenuOpened;
  }

  closeRightMenu() {
    this.rightMenuOpened = false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  changePassword(): void {
    this.router.navigate(['auth/reset-password']);
  }

  onOrganizationChanged(event) {
    this.headerForm.get('appID').reset();
    this.helperService.AppDefinitionChanged.next(null);
    localStorage.setItem('isOrgAdmin', event.isAdministrator);
    this.sharedAppService.currentCloudOrgAdministrator(event.isAdministrator);
    localStorage.setItem('tenantKey', event.tenantKey);
    localStorage.setItem('organizationId', event.id);
    localStorage.setItem('saveOrgId', event.id);
    localStorage.setItem('orgname', event.name);
    this.featureFlagService.getTenantKey(event.tenantKey);
    this.helperService.organizationChanged.next(event.id);
    this.ShelperService.organizationChanged.next(event.id);
    this.sharedAppService.cloudCurrentOrg(event.id);
    this.authService.getbillingStatus();
    let data = []
    data = this.myOrganization.filter(data => data.name === localStorage.getItem('orgname'))
    this.myAppDefinition = data[0].appDefinitions;
    // this.authService.getAppdefinition()
    localStorage.removeItem('AppID');
    localStorage.removeItem('queueItemsState');
    const viewUrl = window.location.href.toString();
    if (viewUrl.includes('view')) {
      this.SharedhttpService.error(
        'Record(s) no longer exists or you do not have authorized access.'
      );
      this.helperService.backScreen();
    }
    // let arr = window.location.href.toString().split('/');
    // let guid = arr[arr.length - 1];
    // if (guid.includes('continuationtoken')) {
    //   guid = guid.split('?')[0]
    //   console.log(guid)
    //   if (this.isGUID(guid)) {
    //     this.router.navigate(['pages/dashboard']);
    //   }
    // }

    // this.authService.getNextBillDate().subscribe((data: any) => {

    //   this.user_email = localStorage.getItem("UserEmail");
    //   this.subscriptionStatus = data?.subscriptionStatus

    //   this.nextBillDate = this.helperService.UTCtoLocalTimeZone({ ...data?.nextBillDate })

    //   var currentDate = moment(new Date());
    //   var givenDate = moment(data?.nextBillDate);
    //   this.dueDate = givenDate.diff(currentDate, 'days') + 1;

    // })
  }

  onAppDefinitionChanged(event) {
    let appID = event;
    if (appID) {
      this.SharedhttpService.checkRolesStatus(appID.id);
      this.helperService.AppDefinitionChanged.next(appID.id);
      localStorage.setItem('AppID', appID.id);
    } else {
      // this.SharedhttpService.checkRolesStatus(appID.id);
      this.SharedhttpService.checkRolesStatus(undefined);
      this.helperService.AppDefinitionChanged.next(null);
      localStorage.removeItem('AppID');
    }
    this.router.navigate(['pages/dashboard']);
  }

  isGUID(uuid) {
    let result = uuid.match(
      '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
    );
    if (result === null) {
      return false;
    }
    return true;
  }
}
