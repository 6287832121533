import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
// import { LoginGuard } from './@core/guards/login.guard';
import { TermGuard } from './@core/guards/term.guard';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
  {
    path: 'pages',
    // canActivate: [LoginGuard, TermGuard],
    canActivate: [MsalGuard],
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  // {
  //   path: 'forms-designer',
  //   loadChildren: () =>
  //     import('../../../sharedapp/src/app/pages/forms-designer/forms-designer.module').then(
  //       (m) => m.FormsDesignerModule
  //     ),
  // },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
  { path: 'logout', component: LogoutComponent },
  {
    // Needed for handling redirect after login
    path: 'signin-oidc',
    component: MsalRedirectComponent
},
];

const config: ExtraOptions = {
  useHash: false,
  // Don't perform initial navigation in iframes or popups
  initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universa
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
