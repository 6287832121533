import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { businessEnv } from '../../../environments/environment';


@Injectable({
  providedIn: 'root',
})
export class BusinessHttpService {
  countapi = 0;
  @BlockUI() blockUI: NgBlockUI;

  get apiUrl(): string {
    return `${businessEnv.API_URL}/${businessEnv.VERSION_URL}`;
  }

  get apiStartPoints() {
    return `${businessEnv.API_URL}/${businessEnv.VERSION_URL}`;
  }

  get apiStartPointsVersion1() {
    return `${businessEnv.API_URL}/api/v1/`;
  }

  watchtotalSubject = new BehaviorSubject<any>('');
  currentMessagetotal = this.watchtotalSubject.asObservable();

  constructor(
    private http: HttpClient,
    private toastrService: NbToastrService
  ) { }

  getHealthStatus(endpoint: string): Observable<any> {
    return this.http.get(`${businessEnv.healthUrl}/${endpoint}`);
  }

  // Utility method to merge default options
  private mergeDefaultOptions(options?): object {
    const defaultOptions = {
      withCredentials: true
    };
    return {...defaultOptions, ...options};
  }

  get(endpoint: string, options?): Observable<any> {
    const mergedOptions = this.mergeDefaultOptions(options);
    return this.http.get(`${this.apiUrl}${endpoint}`, mergedOptions);
  }

  post(endpoint: string, data?, options?): Observable<any> {
    const mergedOptions = this.mergeDefaultOptions(options);
    return this.http.post(`${this.apiUrl}${endpoint}`, data, mergedOptions);
  }

  put(endpoint: string, data, options?): Observable<any> {
    const mergedOptions = this.mergeDefaultOptions(options);
    return this.http.put(`${this.apiUrl}${endpoint}`, data, mergedOptions);
  }

  patch(endpoint: string, data, options?): Observable<any> {
    const mergedOptions = this.mergeDefaultOptions(options);
    return this.http.patch(`${this.apiUrl}${endpoint}`, data, mergedOptions);
  }

  delete(endpoint: string, options?): Observable<any> {
    const mergedOptions = this.mergeDefaultOptions(options);
    return this.http.delete(`${this.apiUrl}${endpoint}`, mergedOptions);
  }

  // ends here
  // get user info and cloud server features
  getUserInfoAndFeatures(userInfoEndpoint: string, featureFlagsEndpoint: string, options?): Observable<any> {

    let response1 = this.http.get(`${this.apiStartPoints}${userInfoEndpoint}`, options);
    let response2 = this.http.get(`${this.apiStartPoints}${featureFlagsEndpoint}`, options);

    return forkJoin([response1, response2]);

  }
  // below 5 methods are used without organization keyword and organizationId
  getByCompleteUrlVersion1(endpoint: string, options?): Observable<any> {
    return this.http.get(`${this.apiStartPointsVersion1}${endpoint}`, options);
  }
  postByCompleteUrlVersion1(
    endpoint: string,
    data?,
    options?
  ): Observable<any> {
    return this.http.post(
      `${this.apiStartPointsVersion1}${endpoint}`,
      data,
      options
    );
  }

  getByCompleteUrl(endpoint: string, options?): Observable<any> {
    return this.http.get(`${this.apiStartPoints}${endpoint}`, options);
  }

  postByCompleteUrl(endpoint: string, data?, options?): Observable<any> {
    return this.http.post(`${this.apiStartPoints}${endpoint}`, data, options);
  }

  putByCompleteUrl(endpoint: string, data, options?): Observable<any> {
    return this.http.put(`${this.apiStartPoints}${endpoint}`, data, options);
  }

  patchByCompleteUrl(endpoint: string, data, options?): Observable<any> {
    return this.http.patch(`${this.apiStartPoints}${endpoint}`, data, options);
  }

  deleteByCompleteUrl(endpoint: string, options?): Observable<any> {
    return this.http.delete(`${this.apiStartPoints}${endpoint}`, options);
  }
  // ends here

  success(param): void {
    this.toastrService.success(`${param}`, 'Success');
  }

  error(param): void {
    this.toastrService.danger(`${param}`, 'Error');
  }

  warning(param): void {
    this.toastrService.warning(`${param}`, 'Warning');
  }
  primary(param): void {
    this.toastrService.primary(`${param}`, 'Info');
  }

  info(param): void {
    this.toastrService.info(`${param}`, 'Info');
  }

  /// for 429 error with data sharing flag .
  watchtotal(error, time) {
    const errorTime = {
      error,
      time,
    };
    this.countapi = 1;
    this.watchtotalSubject.next(errorTime);
  }

  getOrganizationId(): string {
    return localStorage.getItem('organizationId');
  }
  ///////Loader
  startLoader(message?: string) {
    if (message) this.blockUI.start(message);
    else this.blockUI.start();
  }

  stopLoader() {
    this.blockUI.stop();
  }
}
