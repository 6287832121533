import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbCardModule,
  NbFormFieldModule,
} from '@nebular/theme';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { BlockUIModule } from 'ng-block-ui';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { TokenInterceptor } from './@core/interceptor/token.interceptor';
import { ThemeModule } from './@theme/theme.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { PwaComponent } from './pwa/pwa.component';

import { FormsModule } from '@angular/forms';
import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from './customUrlSerializer/customUrlSerializer';
import { MsalInterceptor, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MsalModule, MsalGuardConfiguration, MsalInterceptorConfiguration, ProtectedResourceScopes } from '@azure/msal-angular';

import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig, protectedResources } from './auth-config';
import { LogoutComponent } from './logout/logout.component';


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
* MSAL Angular will automatically retrieve tokens for resources
* added to protectedResourceMap. For more info, visit:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
*/
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(protectedResources.apiTodoList.endpoint, [
      {
          httpMethod: 'GET',
          scopes: [...protectedResources.apiTodoList.scopes.read]
      },
      {
          httpMethod: 'POST',
          scopes: [...protectedResources.apiTodoList.scopes.write]
      },
      {
          httpMethod: 'PUT',
          scopes: [...protectedResources.apiTodoList.scopes.write]
      },
      {
          httpMethod: 'DELETE',
          scopes: [...protectedResources.apiTodoList.scopes.write]
      }
  ]);

  return {
      interactionType: InteractionType.Popup,
      protectedResourceMap,
  };
}

/**
* Set your default interaction type for MSALGuard here. If you have any
* additional scopes you want the user to consent upon login, add them here as well.
*/
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
      interactionType: InteractionType.Redirect,
      authRequest: loginRequest
  };
}


@NgModule({
  declarations: [AppComponent, PwaComponent, LogoutComponent],
  imports: [
    FormsModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    ThemeModule.forRoot(),
    BlockUIModule.forRoot(),
    ToastrModule.forRoot(),
    MsalModule,
    NgbModalModule,
    NgIdleKeepaliveModule.forRoot(),
    NbCardModule,
    // NbFormFieldModule
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
  },
  {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
  },
  {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
  },
  {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    // ConnectionServiceModule,
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {}
}
