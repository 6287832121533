import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
// import { OAuthService } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { businessAuthConfig } from '../../../business-oidc-config';
import { BusinessHelperService } from '../services/helper.service';
import { BusinessHttpService } from '../services/http.service';
import { BusinessAuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private orgId: string;
  private appDefinitionId: string;
  constructor(
    private httpService: BusinessHttpService,
    private authService: BusinessAuthService,
    private helperService: BusinessHelperService,
    private cookieService: CookieService
  ) // private toasterService: NbToastrService
  { }

  apiUrl() {
    this.helperService.getChangedOrgId().subscribe((response) => {
      this.orgId = response;
    });
    this.helperService.getChangedAppId().subscribe((response) => {
      this.appDefinitionId = response;
    });
  }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    const token = localStorage.getItem('id_token');
    if (token) {
      request = this.attachToken(request, token);
      this.apiUrl();
    }
    return next.handle(request).pipe(
      catchError((error) => {
        this.httpService.stopLoader();
        if (
          (error && error.error && error.error.status == 401) ||
          (error && error.status == 401)
        ) {
          // login again
          //show loader
          this.authService.login();
        }
        else if (error.error && error.error.status == 403 || (error && (error.status == 403))) {
          this.httpService.error(`${error.error.serviceErrors[0]}`);
        } else if (error.error && error.error.status == 409) {
          return throwError(error);
        } else if (error && error.error && error.error.status == 422) {
          this.httpService.error(`${error.error.serviceErrors[0]}`);
        } else if (error && error.error && error.error.status == 402) {
          this.httpService.error(
            'Operation was declined by the server. Payment Required'
          );
        } else if (error && error.error && error.error.status == 429) {
          if (error.headers.get('Retry-After')) {
            if (this.httpService.countapi !== 1) {
              this.httpService.watchtotal(
                error.error.status,
                error.headers.get('Retry-After')
              );
            }
          } else if (this.httpService.countapi !== 1) {
            this.httpService.watchtotal(error.error.status, 30);
          }
        } else if (error && error.error && error.error.status != 401) {
          return this.handleError(error);
        }
      })
    );
  }

  handleError(error) {
    if (error instanceof HttpErrorResponse) {
      if (
        (error.error.status == 400 &&
          error.error.serviceErrors &&
          error.error.serviceErrors[0] ==
          'Token is no longer valid. Please log back in.') ||
        error.error.status == 401 ||
        error.status == 401
      ) {
        // unauthorized
      } else if (
        error &&
        error.error &&
        error.error.serviceErrors[0] !=
        'Token is no longer valid. Please log back in.' &&
        error.error.status == 400 &&
        error.error.serviceErrors
      ) {
        this.httpService.error(`${error.error.serviceErrors[0]}`);
      }
    } else return throwError(error);
  }

  attachToken(request: HttpRequest<unknown>, token: string) {
    if (this.appDefinitionId == null) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          OrganizationId: `${this.orgId ? this.orgId: null}`,
        },
      });
    } else if (this.appDefinitionId !== null) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          OrganizationId: `${this.orgId ? this.orgId: null}`,
          appID: `${this.appDefinitionId}`,
        },
      });
    }
  }

}
