import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesizes'
})
export class FileSizePipe implements PipeTransform {
  transform(size: number, extension: string = 'MB') {
    if (+(size / 1024) < 1024) {
      return (size / (1024)).toFixed(2) + 'KB';
    } else
      return (size / (1024 * 1024)).toFixed(2) + extension;
  }
}
