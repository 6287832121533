import { Injectable, TemplateRef } from '@angular/core';
import { NbDialogService } from '@nebular/theme';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialogService: NbDialogService) { }

  openDialog(ref: TemplateRef<any>, closeOnBackdropClick = false) {
    this.dialogService.open(ref, {
      hasBackdrop: true,
      closeOnBackdropClick: closeOnBackdropClick,
    });
  }

  open(componentName, closeOnBackdropClick = false) {
    this.dialogService.open(componentName, {
      hasBackdrop: true,
      closeOnBackdropClick: closeOnBackdropClick,
    });
  }
}
