<div class="container-fluid mt-2">

  <ngx-header></ngx-header>
</div>

<div class="container body">
  <div class="row" style="
    margin-top: 55px;
">
    <div class="col-md-6 col-lg-6">
      <div class="whiteBk">
        <img src="../../assets/images/openbots-businesscenter.svg" class="loginLogo" alt="OpenBots Server Logo" />
        <div class="loginFormInner">
          <h1>OpenBots BusinessCenter requires you to have an Active OpenBots Cloud Subscription</h1>
          <p>


            <a href="https://openbots.ai/">www.openbots.ai</a>
          </p>

        </div>
      </div>
    </div>
    <div class="col-md-6 col-lg-6">
      <img class="imgMaxWidth" src="../../assets/images/login-bk.png" alt="Login" />
    </div>
  </div>
  <div class="col-md-12 col-lg-12 footerPnl">

  </div>
</div>